import React from "react";
import { useHistory } from "react-router";
import { Card, Button } from "@my-scoot/component-library-legacy";
import styles from "./AttendanceCard.module.css";
import Tooltip from "ui/modules/Tooltip";
import { schedule_types_ids, schedule_type_names } from "constants/schedule";
import { trimText } from "utils/string";
import constants from "constants/constants";
import { getListingStatusColor } from "./AttendanceCard.helpers";
import classnames from "classnames";
import { app_pathnames, app_route_keys } from "constants/urlPaths";
import { api } from "data";

export const AttendanceCard = (data) => {
  const history = useHistory();
  const listingStatus = data.listing_status;
  const listingStatusText = constants.listing_status[data.listing_status].name;
  const listingStatusColor = getListingStatusColor(listingStatus);
  const isWebinar = data.listing_type === schedule_types_ids.webinar;
  function onDetailedReportClick() {
    if (isWebinar) {
      const urlToPush = `${api.listingSpecificCommunication.get_offering_details_sessions}/${data.listing_uuid}`;
      history.replace(urlToPush);
    } else {
      history.push(
        app_pathnames[app_route_keys.zoom_attendance_of_listing]({
          listingUuid: data.listing_uuid,
        })
      );
    }
  }

  return (
    <div
      className={classnames(styles.container, "rounded pointer")}
      onClick={onDetailedReportClick}
    >
      <div
        className={classnames(styles.imageContainer, "p-3")}
        style={{
          backgroundImage:
            "url(" +
            "https://upload.wikimedia.org/wikipedia/commons/thumb/b/b6/Image_created_with_a_mobile_phone.png/2560px-Image_created_with_a_mobile_phone.png" +
            ")",
        }}
      >
        <div
          className={classnames(styles.scheduleType, "rounded bg-white px-2")}
        >
          {schedule_type_names[data.listing_type]}
        </div>
      </div>
      <Card
        shadow="shadow_md"
        variant="outlined"
        className={classnames(
          styles.detailsContainer,
          "w-100 p-3 border-top-0"
        )}
      >
        <div
          className={classnames(
            "pb-3 border-bottom d-flex align-items-center justify-content-between",
            styles.title
          )}
        >
          <div className={"font-weight-bold"} title={data.listing_name}>
            {trimText(data.listing_name, 15)}
          </div>
          <div
            style={{ backgroundColor: listingStatusColor }}
            className="px-2 rounded text-white"
          >
            {listingStatusText}
          </div>
        </div>
        <div className="d-flex mb-3 mt-2">
          <div className="mr-3 flex-grow-1">
            <b className={styles.totalCustomers}>
              {data.unique_customer_count}
            </b>
            <div className={styles.dataLabel}>Total Customers</div>
          </div>
          <div className="flex-grow-1">
            <b className={styles.attendedCustomers}>{data.unique_attended}</b>
            <div className="d-flex align-items-start">
              <div
                className={classnames(
                  styles.dataLabel,
                  styles.attendedCustomersDataLabel
                )}
              >
                Attended Customers
              </div>
              <Tooltip
                text={`${
                  isWebinar
                    ? "This shows the count of customers who have attended the session they registered for"
                    : "This shows count of customers who have attended at least 1 out of all sessions"
                }`}
                className={styles.attendedCustomersTooltip}
                tooltipProps={{
                  classes: {
                    tooltipPlacementBottom: styles.tooltipPlacementBottom,
                  },
                }}
              />
            </div>
          </div>
        </div>
        <Button size="thin" fullWidth>
          {
            // Button without click handler as entire  card component is clickable
            isWebinar ? "View" : "Detailed Report"
          }
        </Button>
      </Card>
    </div>
  );
};
