export const filterConfig = [
  {
    source: "title",
    placeholder: "Offering Name",
    type: "input",
  },
];

export const filterChipFormatter = (filter, value) => {
  switch (filter) {
    case "title__icontains":
      return `Offering Name: ${value}`;
    default:
      return null;
  }
};
