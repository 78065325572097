import moment from "moment";
import React from "react";
import { ViewButton } from "../ZoomAttendanceOfListing/components/ViewButton";
import styles from "./WebinarListings.module.css";

export const getColumnConfig = ({ onViewButtonClick }) => [
  {
    field: "customer_name",
    headerName: "Session #",
    width: "250px",
    valueFormatter: (record) => {
      return (
        <div
          className={styles.sessionWrapper}
          onClick={() => {
            onViewButtonClick(record);
          }}
        >
          Session {record.id + 1}
        </div>
      );
    },
    maxCharCount: 25,
  },
  {
    field: "session_date",
    headerName: "Session Date",
    valueFormatter: (record) => (
      <div>{moment(record.start_datetime).format("MMM DD, YYYY")}</div>
    ),
  },
  {
    field: "session_time",
    headerName: "Session Time",
    valueFormatter: (record) => (
      <div>{moment(record.start_datetime).format("hh:mm a")}</div>
    ),
  },
  {
    field: "",
    headerName: "Detailed Report",
    valueFormatter: (record) => {
      return (
        <ViewButton
          onClick={() => {
            onViewButtonClick(record);
          }}
        />
      );
    },
    sortable: false,
    hidden: true,
  },
];

export const filterConfig = [
  {
    source: "session_date",
    placeholder: "Session Date",
    type: "date",
  },
];

export const tabFilterConfig = [
  {
    label: "Full Attendance",
    source: "attendance_type",
    filterValue: 1,
    value: "attendance-type_full",
  },
  {
    label: "Partial Attendance",
    source: "attendance_type",
    filterValue: 3,
    value: "attendance-type_partial",
  },
  {
    label: "No Attendance",
    source: "attendance_type",
    filterValue: 2,
    value: "attendance-type_no",
  },
];

export const tableChipsFormatter = (filter, value) => {
  switch (filter) {
    case "customer_name":
      return `Name: ${value}`;
    case "customer_email":
      return `Email: ${value}`;
    case "customer_phone":
      return `Phone: ${value}`;
    case "attendance_type":
      return null;
    default:
      return filter;
  }
};
