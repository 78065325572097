import React from "react";
import ExlyTableEmptyState from "common/Components/ExlyTable/ExlyTableEmptyState/ExlyTableEmptyState";
import EmptyStateIcon from "assets/vectors/zoomAttendance/emptyState.svg";

const emptyStateProps = {
  empty_list_icon: EmptyStateIcon,
  alt: "No Attendance Enabled Offering Yet",
  content_title: "No Attendance Enabled Offering Yet",
  children:
    "As soon as you enable attendance with Zoom as meeting location in offerings, we'll show them here.",
  primary_cta: {
    children: "Add an offering",
    onClick: () => {
      window.location.href = "/#/CreateScheduleType";
    },
  },
  secondary_cta: { children: "View sample data" },
  preview_image: "zoomAttendancePreviewImage.png",
};

export const EmptyStateComponent = <ExlyTableEmptyState {...emptyStateProps} />;
