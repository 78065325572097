import ExlyModal from "common/Components/ExlyModal";
import React from "react";
import commonStyles from "../CommonStyles.module.css";
import InfoIcon from "@material-ui/icons/Info";
import classnames from "classnames";
import useDesktopMediaQuery from "hooks/useDesktopMediaQuery";
import { TipsAndUpdates } from "common/Components/TipsAndUpdates/TipsAndUpdates";

export const LearnMorePopupWebinar = ({ open, listingName, onClose }) => {
  const isDesktop = useDesktopMediaQuery();
  return (
    <ExlyModal
      open={open}
      title={
        <span className="d-flex align-items-center">
          <InfoIcon
            fontSize="small"
            className={classnames("mr-2", commonStyles.infoIcon)}
          />{" "}
          Learn More
        </span>
      }
      onClose={onClose}
      modal_props={{ showSecondaryBtn: false }}
      mobile_modal_props={{ secondaryAction: false }}
      primaryBtnText="Close"
      onPrimaryBtnClick={onClose}
    >
      <p className="my-4">
        You can check the attendance of all your customers/ clients who bought
        your offering {listingName} here in detail.
      </p>
      <div className="mb-3">
        <b>Full Attendance :</b>
      </div>
      <p className="mb-4">
        By using this filter, you&apos;ll see all the customers/clients who have
        attended the whole session.
      </p>
      <div className="mb-3">
        <b>Partial Attendance :</b>
      </div>
      <p className="mb-4">
        By using this filter, you&apos;ll see all the customers/clients who have
        attended some part of the session so far.
      </p>
      <div className="mb-3">
        <b>No Attendance :</b>
      </div>
      <p className="mb-4">
        By using this filter, you&apos;ll see all the customers/clients who
        didn&apos;t attend the session.
      </p>
      <TipsAndUpdates
        className="my-4"
        iconSize={isDesktop ? "25px" : "35px"}
        tip={
          <div className="small ml-2">
            The attendance update can take 30 minutes to 1 hours once the
            meeting/session has ended
          </div>
        }
      />
    </ExlyModal>
  );
};
