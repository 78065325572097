import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";
import withComponentLibraryTheme from "hocs/withComponentLibraryTheme";
import { getUserChecks } from "utils/AuthUtil";
import { orgPermissions, RESOURCE_KEYS } from "utils/OrgPermissions";
import ExlyTable from "../../../../common/Components/ExlyTable";
import useDesktopMediaQuery from "hooks/useDesktopMediaQuery";
import { Button } from "@my-scoot/component-library-legacy";
import commonStyles from "./CommonStyles.module.css";
import styles from "./ZoomAttendanceOfListing.module.css";
import {
  getColumnConfig,
  filterConfig,
  tabFilterConfig,
  tableChipsFormatter,
} from "./tableConfig";
import { ViewButton } from "./components/ViewButton";
import { CustomerAttendanceForListingPopup } from "./components/CustomerAttendanceForListingPopup";
import { Header } from "common/Components/Header";
import classnames from "classnames";
import { app_route_keys, app_route_titles } from "constants/urlPaths";
import {
  empty_state_props,
  goBack,
  navigateBack,
  sendReminderToJoin,
  showSendReminderToJoinButton,
  showViewButton,
  useStyles,
} from "./ZoomAttendanceOfListing.helper";
import { SendReminderToJoinButton } from "./components/SendReminderToJoinButton";
import { ReminderToJoinPopup } from "./components/ReminderToJoinPopup";
import { LearnMorePopup } from "./components/LearnMorePopup";
import { useNotifications } from "utils/hooks";
import { zoomAttendanceOfListingExporter } from "utils/csvExporters/zoomAttendance/zoomAttendanceOfListing";
import InfoIcon from "@material-ui/icons/Info";
import { dataExists } from "utils/validations";
import { appendUrlParams } from "utils/urlUtils";
import { fetchListingDetails } from "utils/listing/fetchListingDetails";
import constants from "constants/constants";
import { LearnMorePopupWebinar } from "./components/LearnMorePopupWebinar";
import { webinarAttendanceExporter } from "utils/csvExporters/zoomAttendance/webinarAttendanceOfListing";

const ZoomAttendanceOfListing = (props) => {
  const history = useHistory();
  const { notify } = useNotifications();
  const isDesktop = useDesktopMediaQuery();
  const classes = useStyles();
  const [dataOfZoomAttendanceOfListing, setDataOfZoomAttendanceOfListing] =
    useState({});
  const listingUuid = props.match.params.listing_uuid;
  const { listingName = "", listingType } = dataOfZoomAttendanceOfListing;

  useEffect(() => {
    if (!listingUuid) {
      goBack();
      return;
    }
    fetchListingDetails({ listingUuid })
      .then((apiResponse) => {
        const listingData = apiResponse?.data?.event || {};
        const listingName = listingData.title;
        const listingType = listingData.type;
        const newState = { listingUuid, listingName, listingType };

        // listingType can be 0, so we check for dataExists(listingType)
        if (!listingName || !dataExists(listingType)) {
          throw new Error("invalid data: " + JSON.stringify(newState));
        }
        setDataOfZoomAttendanceOfListing(newState);
      })
      .catch((err) => {
        console.log("error occured while calling fetchListingDetails:", err);
        goBack();
      });

    (async () => {
      const liveFeatures = await getUserChecks();
      if (
        !liveFeatures?.is_zoom_attendance_enabled ||
        orgPermissions.isNavigationItemHidden(
          RESOURCE_KEYS.SECTIONS.ZOOM_ATTENDANCE
        )
      ) {
        history.push("/");
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [showLearnMorePopup, setShowLearnMorePopup] = useState(false);
  const onLearnMoreClick = () => {
    setShowLearnMorePopup(true);
  };

  const [
    customerAttendanceForListingPopupProps,
    setCustomerAttendanceForListingPopupProps,
  ] = useState({});

  const onViewButtonClick = (customerData) => {
    history.push({
      search: appendUrlParams("", {
        customer_uuid: customerData.customer_uuid,
      }),
    });
    setCustomerAttendanceForListingPopupProps({ show: true, customerData });
  };

  const onCloseCustomerAttendanceForListingPopup = () => {
    history.goBack();
    setCustomerAttendanceForListingPopupProps({ show: false });
  };

  const [reminderToJoinPopupProps, setReminderToJoinPopupProps] = useState({});

  const onSendReminderToJoinButtonClick = (customerData) => {
    sendReminderToJoin({
      listingUuid,
      customerUuid: customerData.customer_uuid,
    })
      .then(() => {
        setReminderToJoinPopupProps({ show: true, customerData });
      })
      .catch((err) => {
        const errorMsg = err?.message || "";
        if (
          !errorMsg.includes("Try sending communication to this user after")
        ) {
          console.log(
            "error while calling send_reminder_to_customer_to_join in ZoomAttendanceOfListing: ",
            err
          );
        }
        notify(
          errorMsg || "An error occured. Please try again after some time.",
          "error"
        );
      });
  };

  const isWebinar = listingType === constants.scheduleTypesId.webinar;

  const LearnMoreToShow = isWebinar ? LearnMorePopupWebinar : LearnMorePopup;
  const backNavigation = isWebinar ? () => navigateBack(listingUuid) : goBack;
  const HeaderComponentBase = (
    <div className={styles.breadcrumbs} onClick={backNavigation}>
      {app_route_titles[app_route_keys.zoom_attendance]}
    </div>
  );

  const HeaderComponent = isDesktop ? (
    <Header
      title={HeaderComponentBase}
      classNames={{
        container: classnames("border-0 p-0", commonStyles.largeText),
      }}
      onBackClick={backNavigation}
    />
  ) : (
    <div className="d-flex justify-content-between align-items-center">
      {HeaderComponentBase}
      <InfoIcon
        onClick={onLearnMoreClick}
        fontSize="small"
        className={classnames("ml-2", commonStyles.infoIcon)}
      />
    </div>
  );

  return (
    <div className="component-wrapper">
      <ExlyTable
        ra_props={{
          ...props,
          exporter: isWebinar
            ? webinarAttendanceExporter
            : zoomAttendanceOfListingExporter,
        }} // Different exporters for webinar and zoom as different columns are getting rendered
        noExportButton={!orgPermissions.canExport()}
        tableTitle={HeaderComponent}
        layoutProps={{
          secondaryComponent: isDesktop ? (
            <div className="">
              {orgPermissions.canSendEmail() && (
                <Button
                  color="link"
                  onClick={onLearnMoreClick}
                  className={classnames(commonStyles.linkButton, "p-0")}
                >
                  <u>Learn More</u>
                </Button>
              )}
            </div>
          ) : null,
          paddingDesktop: "0",
          paddingBottom: "120px",
          noMobileBoxShadow: true,
        }}
        customBackAction={backNavigation}
        columnConfig={getColumnConfig({
          isDesktop,
          onViewButtonClick,
          onSendReminderToJoinButtonClick,
          listingType,
        })}
        className={styles.gap16}
        tableFilters={filterConfig}
        tabFilterConfig={tabFilterConfig}
        tableChipsFormatter={tableChipsFormatter}
        borderedFields
        fieldsLeftPadded
        drawerFieldsBordered
        fieldsAlignment="space-between"
        drawerFieldsAlignment="space-between"
        recordFooterVariant="secondary"
        getRecordFooterClassName={(record) =>
          showSendReminderToJoinButton({ record, listingType })
            ? classes.reminderToJoinButtonContainer
            : isWebinar && classes.displayNone
        }
        customPrimaryAction={{
          renderCustomAction: (record) => {
            if (listingType === constants.scheduleTypesId.webinar) return null;
            if (showViewButton(record)) {
              return (
                <div className="d-flex justify-content-between w-100 mr-2">
                  <div>Action:</div>
                  <ViewButton
                    onClick={() => {
                      onViewButtonClick(record);
                    }}
                  />
                </div>
              );
            }
            if (showSendReminderToJoinButton({ record, listingType })) {
              return (
                <div className={"d-flex justify-content-end w-100 mr-2"}>
                  <SendReminderToJoinButton
                    onClick={() => {
                      onSendReminderToJoinButtonClick(record);
                    }}
                  />
                </div>
              );
            }
            return null;
          },
        }}
        primaryKey="customer_uuid"
        {...empty_state_props}
      />
      {customerAttendanceForListingPopupProps.show && (
        <CustomerAttendanceForListingPopup
          open={customerAttendanceForListingPopupProps.show}
          customerData={customerAttendanceForListingPopupProps.customerData}
          listingUuid={listingUuid}
          listingName={listingName}
          onClose={onCloseCustomerAttendanceForListingPopup}
        />
      )}
      {reminderToJoinPopupProps.show && (
        <ReminderToJoinPopup
          open={reminderToJoinPopupProps.show}
          customerData={reminderToJoinPopupProps.customerData}
          onClose={() => {
            setReminderToJoinPopupProps({ show: false });
          }}
        />
      )}
      {showLearnMorePopup && (
        <LearnMoreToShow
          open={showLearnMorePopup}
          listingName={listingName}
          onClose={() => {
            setShowLearnMorePopup(false);
          }}
        />
      )}
    </div>
  );
};

export default withComponentLibraryTheme(ZoomAttendanceOfListing);
