import React from "react";
import { makeStyles } from "@material-ui/core";
import constants from "constants/constants";
import { api, dataProvider } from "data";
import EmptyListIcon from "assets/vectors/infoModal/Presentation.svg";

export const goBack = () => {
  window.location.href = "/#/zoom-attendance";
};

export const navigateBack = (listingUuid) => {
  const urlToPush = `/#/${api.listingSpecificCommunication.get_offering_details_sessions}/${listingUuid}`;
  window.location.replace(urlToPush);
};

export const calculateTotalMeetingDuration = (val) => Math.round(val / 60);

export const showViewButton = (record) =>
  calculateTotalMeetingDuration(record.total_meeting_duration) > 0;

export const showSendReminderToJoinButton = ({ record, listingType }) =>
  !showViewButton(record) &&
  listingType !== constants.scheduleTypesId.no_schedule &&
  listingType !== constants.scheduleTypesId.webinar;

export const useStyles = makeStyles((theme) => ({
  reminderToJoinButtonContainer: {
    backgroundColor: theme.palette.basic.danger_red + "20",
  },
  displayNone: {
    display: "none !important",
  },
}));

export const sendReminderToJoin = ({ listingUuid, customerUuid }) =>
  new Promise((resolve, reject) => {
    dataProvider
      .custom_request(
        api.attendance.send_reminder_to_customer_to_join,
        "POST",
        {
          listing_uuid: listingUuid,
          customer_uuid: customerUuid,
        }
      )
      .then((apiResponse) => {
        if (apiResponse?.status !== 200) reject(apiResponse);
        resolve(apiResponse);
      })
      .catch(reject);
  });

export const empty_state_props = {
  empty_list_icon: EmptyListIcon,
  children: (
    <div className="mt-4">
      <div>No attendance data to show yet.</div>
      <div className="mt-3 text-muted small">
        Once a session is completed, processing attendance data can take upto 30
        minutes.
      </div>
    </div>
  ),
  exlyFilteredEmptyStateProps: {
    secondaryText: "Try a different filter",
  },
};
