import ExlyModal from "common/Components/ExlyModal";
import React from "react";
import ReminderToJoinIcon from "assets/vectors/zoomAttendance/reminderToJoin.svg";
import styles from "./ReminderToJoinPopup.module.css";

export const ReminderToJoinPopup = ({ open, customerData, onClose }) => {
  return (
    <ExlyModal
      open={open}
      title="Reminder to Join"
      onClose={onClose}
      modal_props={{ showSecondaryBtn: false }}
      mobile_modal_props={{ secondaryAction: false }}
      primaryBtnText="Close"
      onPrimaryBtnClick={onClose}
    >
      <div className="d-flex flex-column align-items-center m-5 text-center">
        <img src={ReminderToJoinIcon} alt="reminder sent" className="mb-5" />
        <span>
          Reminder has been sent to{" "}
          <b>
            <span className={styles.name}>{customerData.customer_name}</span>
          </b>{" "}
          on WhatsApp & Email to attend the future sessions.
        </span>
      </div>
    </ExlyModal>
  );
};
