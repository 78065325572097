import React from "react";
import Tooltip from "ui/modules/Tooltip";
import { EmailField } from "../../customers/AllCustomers/components/TableColumns/TableColumns.js";
import { Avatar } from "@my-scoot/component-library-legacy";
import { openWhatsAppLink } from "utils/urlUtils";
import { ViewButton } from "./components/ViewButton";
import { WhatsAppPhoneField } from "common/Components/TableFields/TableFields.js";
import { parsePhoneNumber } from "utils/Utils.js";
import {
  calculateTotalMeetingDuration,
  showSendReminderToJoinButton,
  showViewButton,
} from "./ZoomAttendanceOfListing.helper.js";
import { SendReminderToJoinButton } from "./components/SendReminderToJoinButton/index.js";
import styles from "./ZoomAttendanceOfListing.module.css";
import constants from "constants/constants.js";

const AttendanceColumnHeaderBase = () => (
  <>
    Attendance
    <Tooltip
      text="If the customer attends even 5 minutes of a session, it’s considered as attended."
      className="mr-1"
      tooltipProps={{
        classes: {
          tooltipPlacementBottom: styles.tooltipPlacementBottom,
        },
      }}
    />
  </>
);

export const getColumnConfig = ({
  isDesktop,
  onViewButtonClick,
  onSendReminderToJoinButtonClick,
  listingType,
}) => [
  {
    field: "customer_name",
    headerName: "Customer Name",
    renderColumn: isDesktop
      ? undefined
      : (record) => (
          <>
            <div className="d-flex align-items-center font-weight-bold">
              <Avatar label={record.customer_name} className="mr-2" />
              {record.customer_name}
            </div>
            <img
              alt="whatsapp"
              src={require("assets/images/whatsapp.png")}
              width="30px"
              height="30px"
              onClick={() =>
                openWhatsAppLink(
                  parsePhoneNumber(record, {
                    countryCodeKey: "customer_country_code",
                    phoneNumberKey: "customer_phone_numer",
                  })
                )
              }
            />
          </>
        ),
    fixed: "left",
    maxCharCount: 25,
  },
  {
    field: "customer_email",
    headerName: "Email",
    valueFormatter: (record) => (
      <EmailField record={record} source="customer_email" />
    ),
  },
  {
    field: "customer_phone_numer",
    headerName: "Phone",
    valueFormatter: (record) => (
      <WhatsAppPhoneField
        record={record}
        source="customer_phone_numer"
        countryCodeKey="customer_country_code"
      />
    ),
  },
  {
    field: "attendance",
    renderColumn: isDesktop
      ? undefined
      : (record) => (
          <>
            <div className="d-flex font-weight-bold">
              <AttendanceColumnHeaderBase />:
            </div>
            {`${record.session_attended} / ${record.total_session}`}
          </>
        ),
    headerName: (
      <span className="d-flex">
        <AttendanceColumnHeaderBase />
      </span>
    ),
    hiddenDesktopColumn: listingType === constants.scheduleTypesId.webinar,
    hidden: listingType === constants.scheduleTypesId.webinar,
    valueFormatter: (record) =>
      `${record.session_attended} / ${record.total_session}`,
  },
  {
    field: "attended",
    headerName: "Attended",
    valueFormatter: (record) => (
      <>
        <div
          className={`d-flex font-weight-600 ${
            record.session_attended > 0 ? "text-success" : "text-danger"
          } `}
        >
          {record.session_attended > 0 ? "Yes" : "No"}
        </div>
      </>
    ),
    hiddenDesktopColumn: listingType !== constants.scheduleTypesId.webinar,
  },
  {
    field: "total_meeting_duration",
    headerName: "Total Minutes Attended",
    valueFormatter: (record) =>
      calculateTotalMeetingDuration(record.total_meeting_duration).toString(),
  },

  {
    field: "",
    headerName: "Action",
    valueFormatter: (record) => {
      if (showViewButton(record)) {
        return (
          <ViewButton
            onClick={() => {
              onViewButtonClick(record);
            }}
          />
        );
      }
      if (
        showSendReminderToJoinButton({ record, listingType }) &&
        listingType !== constants.scheduleTypesId.webinar
      ) {
        return (
          <SendReminderToJoinButton
            onClick={() => {
              onSendReminderToJoinButtonClick(record);
            }}
          />
        );
      }
      return "N/A";
    },
    sortable: false,
    fixed: "right",
  },
];

export const filterConfig = [
  {
    source: "customer_name",
    placeholder: "Name",
    type: "input",
    disableContains: true,
  },
  {
    source: "customer_email",
    placeholder: "Email",
    type: "input",
    disableContains: true,
  },
  {
    source: "customer_phone",
    placeholder: "Phone",
    type: "input",
    disableContains: true,
  },
];

export const tabFilterConfig = [
  {
    label: "Full Attendance",
    source: "attendance_type",
    filterValue: 1,
    value: "attendance-type_full",
  },
  {
    label: "Partial Attendance",
    source: "attendance_type",
    filterValue: 3,
    value: "attendance-type_partial",
  },
  {
    label: "No Attendance",
    source: "attendance_type",
    filterValue: 2,
    value: "attendance-type_no",
  },
];

export const tableChipsFormatter = (filter, value) => {
  switch (filter) {
    case "customer_name":
      return `Name: ${value}`;
    case "customer_email":
      return `Email: ${value}`;
    case "customer_phone":
      return `Phone: ${value}`;
    case "attendance_type":
      return null;
    default:
      return filter;
  }
};
