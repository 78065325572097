import { creatorToolTheme } from "@my-scoot/component-library-legacy";
import constants from "constants/constants";

const availability_status = constants.availability_status;

export function getListingStatusColor(listingStatus) {
  if (listingStatus === availability_status.live)
    return creatorToolTheme.palette.fine_pine.main;
  if (listingStatus === availability_status.expired)
    return creatorToolTheme.palette.basic.danger_red;
  if (
    [availability_status.deleted, availability_status.sold_out].includes(
      listingStatus
    )
  )
    return creatorToolTheme.palette.warning.main;
  return creatorToolTheme.palette.warning.main;
}
