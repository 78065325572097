import { useHistory } from "react-router";
import ExlyTableFilters from "common/Components/ExlyTable/ExlyTableFilters";
import ExlyFilteredEmptyState from "common/Components/ExlyTable/ExlyTableFilters/ExlyTableFilterEmptyState";
import { Header } from "common/Components/Header";
import { Pagination } from "common/Components/Pagination";
import withComponentLibraryTheme from "hocs/withComponentLibraryTheme";
import useDesktopMediaQuery from "hooks/useDesktopMediaQuery";
import React, { useEffect, useMemo } from "react";
import { useListController } from "react-admin";
import { getUserChecks } from "utils/AuthUtil";
import Tooltip from "ui/modules/Tooltip";
/*
// TODO: remove this code and the `zoomAttendanceExporter` function if not used till 13th April 2023
import { zoomAttendanceExporter } from "utils/csvExporters/zoomAttendance/zoomAttendance";
*/
import { orgPermissions, RESOURCE_KEYS } from "utils/OrgPermissions";
import { is_empty } from "utils/validations";
import { AttendanceCard } from "./components/AttendanceCard/AttendanceCard";
import { EmptyStateComponent } from "./components/EmptyStateComponent";
import { filterChipFormatter, filterConfig } from "./tableConfig";
import styles from "./ZoomAttendanceHome.module.css";
import classnames from "classnames";
import { app_route_keys, app_route_titles } from "constants/urlPaths";

const ZoomAttendanceHome = (props) => {
  const history = useHistory();
  const isDesktop = useDesktopMediaQuery();

  useEffect(() => {
    (async () => {
      const liveFeatures = await getUserChecks();
      if (
        !liveFeatures?.is_zoom_attendance_enabled ||
        orgPermissions.isNavigationItemHidden(
          RESOURCE_KEYS.SECTIONS.ZOOM_ATTENDANCE
        )
      ) {
        history.push("/");
      }
    })();
  }, []);

  const {
    data,
    loading,
    page,
    total,
    setPage,
    setFilters,
    filterValues,
    loaded,
  } = useListController(props);

  const dataArray = useMemo(() => Object.values(data), [data]);

  if (is_empty(data) && loaded && is_empty(filterValues.title__icontains))
    return EmptyStateComponent;

  /*
  // TODO: remove this code and the `zoomAttendanceExporter` function if not used till 13th April 2023
  const exporter = () => {
    zoomAttendanceExporter(dataArray);
  };
  */

  return (
    <>
      <Header
        title={
          <>
            {app_route_titles[app_route_keys.zoom_attendance]}{" "}
            <Tooltip
              text="Attendance is tracked only for offerings created with Zoom set as meeting location."
              className="ml-1"
            />
          </>
        }
        hideBackButton={isDesktop}
        onBackClick={() => (window.location.href = "/#/")}
        classNames={{ container: isDesktop ? "border-bottom-0 pl-4" : "pl-4" }}
      />
      <div className={classnames(isDesktop ? "" : "mt-4 pt-2", "mb-5 mx-3")}>
        <ExlyTableFilters
          filters={filterConfig}
          tableChipsFormatter={filterChipFormatter}
          setFilters={setFilters}
          filterValues={filterValues}
          isDesktop={isDesktop}
          noExportButton
          /*
          // TODO: remove this code and the `zoomAttendanceExporter` function if not used till 13th April 2023
          exporter={exporter}
          noExportButton={!orgPermissions.canExport()}
          */
        />
        <div
          className={classnames(
            "d-flex flex-wrap",
            isDesktop ? "" : "justify-content-center",
            styles.cardsContainer
          )}
        >
          {!loading &&
            dataArray.map((item) => (
              <AttendanceCard {...item} key={item.listing_uuid} />
            ))}
        </div>
        {is_empty(dataArray) && !is_empty(filterValues.title__icontains) ? (
          <ExlyFilteredEmptyState />
        ) : null}
        <Pagination total={total} page={page} onPageChange={setPage} />
      </div>
    </>
  );
};

export default withComponentLibraryTheme(ZoomAttendanceHome);
