import React from "react";
import { Button } from "@my-scoot/component-library-legacy";
import classnames from "classnames";
import commonStyles from "../../CommonStyles.module.css";
import styles from "./ViewButton.module.css";
import VisibilityIcon from "@material-ui/icons/Visibility";
import useDesktopMediaQuery from "hooks/useDesktopMediaQuery";

export const ViewButton = ({ onClick }) => {
  const isDesktop = useDesktopMediaQuery();

  return (
    <Button
      color="link"
      className={classnames(
        "p-0 m-0",
        commonStyles.linkButton,
        styles.viewButton
      )}
      onClick={onClick}
    >
      <span
        className={classnames(
          "d-flex align-items-center",
          isDesktop ? "" : "flex-row-reverse"
        )}
      >
        <VisibilityIcon fontSize="small" />
        <span>&nbsp;&nbsp;</span>
        <span className={styles.viewButtonText}>
          <u>View Report</u>
        </span>
      </span>
    </Button>
  );
};
