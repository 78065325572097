import ExlyModal from "common/Components/ExlyModal";
import React, { useMemo } from "react";
import { Avatar, Button } from "@my-scoot/component-library-legacy";
import EmailField from "ui/Fields/EmailField";
import classnames from "classnames";
import useDesktopMediaQuery from "hooks/useDesktopMediaQuery";
import { getWhatsAppLink } from "utils/urlUtils";
import commonStyles from "../../CommonStyles.module.css";
import styles from "./CustomerAttendanceForListingPopup.module.css";
import GetAppIcon from "@material-ui/icons/GetApp";
import {
  CUSTOMER_ATTENDANCE_FOR_LISTING_RA_LAYOUT_PROPS,
  CUSTOMER_ATTENDANCE_FOR_LISTING_RA_PROPS,
  getColumnConfig,
  table_col_keys,
} from "./tableConfig";
import { customerAttendanceForListingExporter } from "utils/csvExporters/zoomAttendance/customerAttendanceForListing";
import { orgPermissions } from "utils/OrgPermissions";
import ExlyTable from "common/Components/ExlyTable";
import { useListController } from "react-admin";
import CloseIcon from "@material-ui/icons/Close";

const commonProps = { modalPaperClassName: styles.root, customFooter: <></> };

export const CustomerAttendanceForListingPopup = ({
  open,
  customerData,
  listingName,
  onClose,
}) => {
  const isDesktop = useDesktopMediaQuery();
  const { data } = useListController(CUSTOMER_ATTENDANCE_FOR_LISTING_RA_PROPS);

  const exporter = () => {
    customerAttendanceForListingExporter(
      Object.values(data).map((i) => ({
        ...i,
        [table_col_keys.listing_name]: listingName,
      }))
    );
  };

  const ModalHeader = (
    <div
      className={classnames(
        styles.headerContainer,
        "border-bottom d-flex justify-content-between align-items-center"
      )}
    >
      <div className={classnames("d-flex", isDesktop && "align-items-center")}>
        <Avatar
          className={classnames(styles.avatar, "mr-3")}
          label={customerData.customer_name}
        />{" "}
        <div>
          <div>{customerData.customer_name}</div>
          <div
            className={classnames(
              "small",
              isDesktop ? "d-flex align-items-center" : ""
            )}
          >
            <EmailField record={customerData} source="customer_email" />
            <a
              target="_blank"
              rel="noopener noreferrer"
              className={classnames(
                isDesktop && "ml-2 pl-2 border-left",
                "link d-flex align-items-center",
                commonStyles.linkButton
              )}
              href={getWhatsAppLink(customerData.customer_phone_numer)}
            >
              {customerData.customer_phone_numer}
              <img
                alt="whatsapp"
                src={require("assets/images/whatsapp.png")}
                width="24px"
                height="24px"
                className="ml-2"
              />
            </a>
          </div>
        </div>
      </div>
      <CloseIcon onClick={onClose} className="pointer" />
    </div>
  );

  const columnConfig = useMemo(
    () => getColumnConfig({ listingName }),
    [listingName]
  );

  return (
    <ExlyModal
      open={open}
      customHeader={ModalHeader}
      modal_props={commonProps}
      mobile_modal_props={commonProps}
      onClose={onClose}
    >
      <div
        className={classnames(styles.section, "d-flex justify-content-between")}
      >
        <div className={styles.activity}>Activity</div>
        {orgPermissions.canExport() ? (
          <Button
            color="link"
            size="thin"
            className={classnames("p-0 m-0", commonStyles.linkButton)}
            onClick={exporter}
          >
            <GetAppIcon />
            {isDesktop ? <>&nbsp;Export Data</> : ""}
          </Button>
        ) : null}
      </div>
      <ExlyTable
        noExportButton
        ra_props={{
          ...CUSTOMER_ATTENDANCE_FOR_LISTING_RA_PROPS,
          exporter: customerAttendanceForListingExporter,
        }}
        columnConfig={columnConfig}
        layoutProps={CUSTOMER_ATTENDANCE_FOR_LISTING_RA_LAYOUT_PROPS}
        fieldsLeftPadded
        borderedFields
        drawerFieldsBordered
        fieldsAlignment="space-between"
        drawerFieldsAlignment="space-between"
        recordFooterVariant="secondary"
        primaryKey="first_join_time"
      />
    </ExlyModal>
  );
};
