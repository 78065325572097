import { app_route_ids, app_route_keys } from "constants/urlPaths";
import { format, parse } from "date-fns";
import {
  date_fns_formats,
  parseDateStringForGivenTz,
} from "utils/dateTimeUtils";
import { trimText } from "utils/string";
import { handleInputDateFormat } from "utils/Utils";
import { calculateTotalMeetingDuration } from "../../ZoomAttendanceOfListing.helper";

export const table_col_keys = {
  listing_name: "listing_name",
  seconds_attended: "seconds_attended",
  session_date: "session_date",
  first_join_time: "first_join_time",
  last_leave_time: "last_leave_time",
};

export const formatTime = (val) =>
  format(parseDateStringForGivenTz(val), date_fns_formats.hour_min__AMPM);

export const getColumnConfig = ({ listingName }) => [
  {
    field: table_col_keys.listing_name,
    headerName: "Offering Name",
    valueFormatter: () => trimText(listingName, 25),
  },
  {
    field: table_col_keys.seconds_attended,
    headerName: "Minutes Attended",
    valueFormatter: (record) =>
      calculateTotalMeetingDuration(
        record[table_col_keys.seconds_attended]
      ).toString(),
  },
  {
    field: table_col_keys.session_date,
    headerName: "Date of Session",
    valueFormatter: (record) =>
      format(
        parse(record[table_col_keys.session_date], "dd-MM-yyyy", new Date()),
        handleInputDateFormat()
      ),
  },
  {
    field: table_col_keys.first_join_time,
    headerName: "First Join Time",
    valueFormatter: (record) =>
      formatTime(record[table_col_keys.first_join_time]),
  },
  {
    field: table_col_keys.last_leave_time,
    headerName: "Last Leave Time",
    valueFormatter: (record) =>
      formatTime(record[table_col_keys.last_leave_time]),
  },
];

const pathName =
  app_route_ids[app_route_keys.zoom_attendance_of_listing_for_customer];

export const CUSTOMER_ATTENDANCE_FOR_LISTING_RA_PROPS = {
  basePath: pathName,
  history: {
    location: {
      pathname: pathName,
      search: "",
      hash: "",
    },
  },
  location: {
    pathname: pathName,
    search: "",
    hash: "",
  },
  match: {
    path: pathName,
    url: pathName,
    isExact: true,
    params: {},
  },

  resource: pathName,
  hasList: true,
  hasEdit: false,
  hasShow: false,
  hasCreate: false,
  syncWithLocation: false,
};

export const CUSTOMER_ATTENDANCE_FOR_LISTING_RA_LAYOUT_PROPS = {
  layoutDesktopMargin: "0 0 24px 0",
  paddingMobile: "0px",
  noMobileBoxShadow: true,
  showFixedBars: true,
};
