import React from "react";
import withComponentLibraryTheme from "hocs/withComponentLibraryTheme";
import ExlyTable from "../../../../common/Components/ExlyTable";
import useDesktopMediaQuery from "hooks/useDesktopMediaQuery";
import commonStyles from "./CommonStyles.module.css";
import styles from "./WebinarListings.module.css";
import {
  getColumnConfig,
  filterConfig,
  tableChipsFormatter,
} from "./tableConfig";
import { Header } from "common/Components/Header";
import classnames from "classnames";
import {
  app_pathnames,
  app_route_keys,
  app_route_titles,
} from "constants/urlPaths";
import { useHistory } from "react-router";

import {
  empty_state_props,
  goBack,
} from "../ZoomAttendanceOfListing/ZoomAttendanceOfListing.helper";
import { useParams } from "react-router-dom";
import { ViewButton } from "../ZoomAttendanceOfListing/components/ViewButton";

const WebinarListingsAttendance = (props) => {
  const params = useParams();

  const listingUuid = params.listing_uuid; //getting listing uuid
  const history = useHistory();

  const isDesktop = useDesktopMediaQuery();
  const onViewButtonClick = (record) => {
    history.push(
      app_pathnames[app_route_keys.live_webinar_attendance]({
        listingUuid: listingUuid,
        slotUuid: record.slot_uid,
      })
    );
  };

  const HeaderComponentBase = (
    <div className={styles.breadcrumbs} onClick={goBack}>
      {`Webinar ${app_route_titles[app_route_keys.zoom_attendance]}`}
    </div>
  );

  const HeaderComponent = isDesktop ? (
    <Header
      title={HeaderComponentBase}
      classNames={{
        container: classnames("border-0 p-0", commonStyles.largeText),
      }}
      onBackClick={goBack}
    />
  ) : (
    <div className="d-flex justify-content-between align-items-center">
      {HeaderComponentBase}
    </div>
  );

  return (
    <div className={isDesktop && "component-wrapper"}>
      <ExlyTable
        ra_props={{ ...props }}
        noExportButton={true}
        tableTitle={HeaderComponent}
        customBackAction={goBack}
        layoutProps={{
          paddingDesktop: "0",
          paddingBottom: "120px",
          noMobileBoxShadow: true,
        }}
        renderSecondaryAction={() => <strong>Detailed Report:</strong>}
        columnConfig={getColumnConfig({
          isDesktop,
          onViewButtonClick,
        })}
        tableFilters={filterConfig}
        tableChipsFormatter={tableChipsFormatter}
        borderedFields
        fieldsLeftPadded
        drawerFieldsBordered
        fieldsAlignment="space-between"
        drawerFieldsAlignment="space-between"
        recordFooterVariant="secondary"
        customPrimaryAction={{
          renderCustomAction: (record) => {
            return (
              <ViewButton
                onClick={() => {
                  onViewButtonClick(record);
                }}
              />
            );
          },
        }}
        primaryKey="customer_uuid"
        {...empty_state_props}
      />
    </div>
  );
};

export default withComponentLibraryTheme(WebinarListingsAttendance);
